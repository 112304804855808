import React, { PureComponent, createRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'next-i18next';

import { isTrueOrZero } from '@common/methods/isTrueOrZero';
import { regexString } from '@legacyApp/client/modules/app/regexString';
import { mathService } from '@legacyApp/client/modules/math/mathService';
import { translationService } from '@legacyApp/client/modules/translation/translationService';
import { enhanceComponent } from '@legacyApp/client/wrappers/enhanceComponent';
import { countPrecision } from '@legacyApp/methods/math/countPrecision';
import { scientificToDecimal } from '@legacyApp/methods/math/scientificToDecimal';
import { gameInputActive } from '@modules/games/Game/store/game/game.actions';

import { InputStyle } from '@legacyApp/client/components/input/styled/input.style';

class Input extends PureComponent {
	static propTypes = {
		value: PropTypes.any,
		onChange: PropTypes.func,
		onChangeDelayed: PropTypes.func,
		validate: PropTypes.func,
		onFocusOut: PropTypes.func,
		onClick: PropTypes.any,
		readOnly: PropTypes.any,
		precision: PropTypes.any,
		maxLength: PropTypes.any,
		isDisabled: PropTypes.any,
		placeholder: PropTypes.any,
		type: PropTypes.any,
		name: PropTypes.any.isRequired,
		inputClasses: PropTypes.any,
		timeout: PropTypes.any,
		showAlert: PropTypes.func,
		translateValue: PropTypes.bool,
		noTranslatePlaceholder: PropTypes.bool,
		gameInputId: PropTypes.string,
		t: PropTypes.func,
		toggleActive: PropTypes.func,
		light: PropTypes.bool,
		modal: PropTypes.bool,
		id: PropTypes.any,
		localTheme: PropTypes.any,
		step: PropTypes.number,
		disableEmpty: PropTypes.bool,
	};

	constructor(props) {
		super(props);

		this.state = {
			value: '',
			timeout: 100,
			focused: false,
		};

		this.input = null;

		this.element = createRef();
	}

	componentWillUnmount() {
		if (this.input) {
			clearTimeout(this.input);
		}

		this.toggleActive(null);
	}

	componentDidMount() {
		this.toggleActive(true);

		if (isTrueOrZero(this.props.value)) {
			this.setState({
				value: this.props.value,
			});
		}

		if (this.props.timeout) {
			this.setState({
				timeout: this.props.timeout,
			});
		}
	}

	toggleActive = (bool) => {
		if (this.props.gameInputId) {
			const onModal = this.element.current
				? this.element.current.closest('.modal')
				: true;

			if (bool !== null) {
				bool = this.props.modal ? !!onModal : true;
			}

			this.props.toggleActive(bool, onModal);
		}
	};

	componentDidUpdate(prevProps) {
		if (
			prevProps.value !== this.props.value ||
			this.state.value !== this.props.value
		) {
			if (this.props.isDisabled || this.props.readOnly === false) {
				this.setState({
					value: this.props.value,
				});
			} else {
				// console.log(this.props.id, 'updatedFromProps', this.props.value);
				if (!this.state.focused) {
					// another game autobet running in background could overwrite values
					this.setState({
						value: this.props.value,
					});
				}
			}
		}

		if (prevProps.modal !== this.props.modal) {
			this.toggleActive();
		}
	}

	verifyPrecision(value) {
		return !(
			isTrueOrZero(this.props.precision) &&
			countPrecision(value) > this.props.precision
		);
	}

	verifyLength(value) {
		return !(
			this.props.maxLength &&
			value &&
			value.length > this.props.maxLength
		);
	}

	fixNumberType = (value) => {
		if (typeof value !== 'string' || this.props.type !== 'number') {
			return value;
		}

		if (value.indexOf(' ') > -1) {
			value = value.split(' ').join('');
		}

		if (['.', ','].indexOf(value[0]) > -1) {
			return `0${value}`;
		}

		if (this.checkLastChar(value, [',', '.'])) {
			const string = value.substring(0, value.length - 1);

			if (string.indexOf(',') > -1 || string.indexOf('.') > -1) {
				return string;
			}
		}

		if (value.indexOf('.') > -1) {
			value = value.split(',').join('.');

			value = this.clearDoubled(value);
		}
		if (value.indexOf(',') > -1) {
			value = value.split(',').join('.');

			value = this.clearDoubled(value, '.');
		}

		return value;
	};

	clearDoubled = (value, separator = '.') => {
		if (value.indexOf(`${separator}${separator}`) > -1) {
			return this.clearDoubled(
				value.split(`${separator}${separator}`).join(`${separator}`),
			);
		}

		return value;
	};

	onKeyPress = (event) => {
		const pattern = this.getCharPattern();

		if (!pattern) {
			return true;
		}

		const charCode =
			typeof event.which == 'undefined' ? event.keyCode : event.which;

		const charStr = String.fromCharCode(charCode);

		const regex = new RegExp(pattern, 'g');

		const regexResult = regexString(regex, charStr);

		if (!regexResult[1]) {
			event.preventDefault();
		}
	};

	handleChange = (event) => {
		let value = `${event.target.value}`;

		if (this.input) {
			clearTimeout(this.input);
		}

		const valueToCheck = `${this.getValue(value)}`;
		if (
			!this.verifyPrecision(valueToCheck) ||
			!this.verifyLength(valueToCheck)
		) {
			const currValue = `${this.getValue()}`;

			if (
				isTrueOrZero(valueToCheck?.length) &&
				isTrueOrZero(currValue.length) &&
				valueToCheck?.length < currValue.length
			) {
				value = null;
			} else {
				return;
			}
		}

		value = this.fixNumberType(value);

		if (this.props.onChange) {
			this.props.onChange(value);
		}

		this.setState({
			value,
		});

		if (!this.props.onChangeDelayed) {
			return;
		}

		this.input = setTimeout(() => {
			this.props.onChangeDelayed(value);
		}, this.state.timeout);
	};

	handleOnClick = () => {
		if (this.props.onClick) {
			this.props.onClick();
		}
	};

	onKeyboard = (event) => {
		if (this.props.type === 'number' && event.keyCode !== 8) {
			let value = `${event.target.value}`;

			if (!this.verifyPrecision(value) || !this.verifyLength(value)) {
				event.persist();
				event.stopPropagation();
				event.preventDefault();
			}
		}
	};

	getPlaceholder() {
		if (this.props.noTranslatePlaceholder) {
			return this.props.placeholder;
		}

		return translationService.translateString(
			this.props.placeholder,
			this.props.t,
		);
	}

	onFocus = () => {
		this.setState({
			focused: true,
		});
	};

	onFocusOut = (e) => {
		this.setState({
			focused: false,
		});

		if (this.state.value === '' && this.props.disableEmpty) {
			return this.setState({
				value: this.props.value,
			});
		}

		if (!this.props.onFocusOut) {
			return;
		}

		if (!this.props.onFocusOut(e.target.value)) {
			this.setState({
				value: this.props.value,
			});
		}
	};

	checkLastChar = (value, data = []) => {
		if (!value || !value.length) {
			return false;
		}

		return data.indexOf(value[value.length - 1]) > -1;
	};

	parseValue = (value = this.state.value) => {
		if (this.isNumberType()) {
			if (value !== '' && parseFloat(value) !== 0 && !!parseFloat(value)) {
				return scientificToDecimal(value);
			}

			if (this.checkLastChar(value, [',', '.']) || Number(value) === 0) {
				return value;
			}

			if (String(value) === '0') {
				return 0;
			}

			return '';
		}

		return value;
	};

	getValue = (value = this.state.value) => {
		let parsed = this.parseValue(value);

		if (this.props.translateValue) {
			parsed = translationService.translateString(parsed, this.props.t);
		}

		if (!isTrueOrZero(parsed)) {
			return '';
		}

		return parsed;
	};

	isNumberType = (value = this.state.value) => {
		return (
			this.props.type === 'number' && !this.checkLastChar(value, [',', '.'])
		);
	};

	getType = () => {
		if (this.props.type === 'number') {
			return 'text';
		}

		return this.props.type ? this.props.type : 'text';
	};

	getCharPattern = () => {
		if (this.props.type === 'number') {
			return '([0-9,.-])?';
		}

		if (this.props.type === 'date') {
			return '([0-9-])?';
		}

		return null;
	};

	render() {
		const placeholder = this.getPlaceholder();

		const type = this.getType();

		const name = this.props.name ? this.props.name : placeholder;

		const inputClasses = this.props.inputClasses || '';

		const value = this.getValue();

		const disabled = !!this.props.isDisabled;

		const readOnly =
			this.props.readOnly !== undefined
				? this.props.readOnly
				: !!this.props.onClick;

		const isValueObject = typeof value === 'object';

		return (
			<InputStyle
				$icon={null}
				$isLabel={null}
				as={isValueObject ? 'div' : undefined}
				localTheme={{
					light: this.props.light,
					...(this.props.localTheme || {}),
				}}
				className={classnames({
					'input-light': this.props.light,
					[inputClasses]: inputClasses,
				})}
				ref={this.element}
				id={this.props.id}
				onChange={this.handleChange}
				onKeyPress={this.onKeyPress}
				value={value}
				placeholder={placeholder}
				type={type}
				step={
					this.props.step ||
					(type === 'number' && isTrueOrZero(this.props.precision)
						? mathService.getMinValueByPrecision(this.props.precision)
						: undefined)
				}
				onKeyDown={this.onKeyboard}
				onBlur={this.onFocusOut}
				onFocus={this.onFocus}
				autocomplete="on"
				onClick={this.handleOnClick}
				name={name}
				readOnly={readOnly}
				title=""
				disabled={disabled}
			>
				{isValueObject ? value : null}
			</InputStyle>
		);
	}
}

Input = enhanceComponent({ Input });

const mapStateToProps = (state) => {
	return {
		modal: !!state.modal?.id,
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		toggleActive: (bool, onModal) => {
			if (!props.gameInputId) {
				return;
			}
			dispatch(gameInputActive(props.gameInputId, bool, onModal));
		},
	};
};

Input = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withTranslation('common'),
)(Input);

export { Input };
