import { GameType } from '../../constants/availableGames';

export const BET = 'BET';
export const BET_RESULT = 'BET_RESULT';

export const BET_THROTTLED = 'BET_THROTTLED';

export const SET_GAME = 'SET_GAME';
export const GAME_INPUT_ACTIVE = 'GAME_INPUT_ACTIVE';

export const GAME_SET_AMOUNT_DEFAULT = 'GAME_SET_AMOUNT_DEFAULT';
export const GAME_SET_WIN_CHANCE_DEFAULT = 'GAME_SET_WIN_CHANCE_DEFAULT';

export const GAME_UPDATE_AMOUNT = 'GAME_UPDATE_AMOUNT';
export const GAME_UPDATE_PAYOUT = 'GAME_UPDATE_PAYOUT';
export const GAME_UPDATE_WIN_CHANCE = 'GAME_UPDATE_WIN_CHANCE';
export const GAME_UPDATE_PROFIT = 'GAME_UPDATE_PROFIT';
export const GAME_UPDATE_FORM_LOCK = 'GAME_UPDATE_FORM_LOCK';
export const BET_RESPONSE_ANIMATION = 'BET_RESPONSE_ANIMATION';
export const BET_UPDATE_ANIMATED = 'BET_UPDATE_ANIMATED';
export const GAME_UPDATE_BET_ANIMATED_LAST_BALANCE =
	'GAME_UPDATE_BET_ANIMATED_LAST_BALANCE';
export const GAME_BET_RESULT = (game: GameType) => `${BET_RESULT}_${game}`;
export const GAME_UPDATE_BETS_IN_ANIMATION = 'GAME_UPDATE_BETS_IN_ANIMATION';
