import styled, { css } from 'styled-components';
import classNames from 'classnames';

import { inputHeight } from '@legacyApp/client/modules/style/defaults';

import { Button } from '@legacyApp/client/components/button';
import { InputStyled } from '@ui/input/Input.styled';

export interface InputStyleProps {
	messageRight?: boolean;
	localTheme?: Record<string, any>;
}

const getInputBackgroundColor = ({ localTheme }: Record<string, any>) => {
	if (localTheme && localTheme.light) {
		return css`var(--color-dark-400)`;
	}
	return css`var(--color-dark-500)`;
};

const inputMessage = css<InputStyleProps>`
	max-width: unset;
	${({ messageRight }) =>
		messageRight
			? css`
					position: absolute;
					right: 0;
					top: 2px;
			  `
			: ''}
`;

export const InputStyle = styled(InputStyled).attrs(({ className }) => ({
	className: classNames('input text-style-sm-medium', className),
}))<InputStyleProps>`
	background-color: ${getInputBackgroundColor}!important;
	width: 100%;
	margin-top: 0;
	padding: 8px;
	${inputHeight};

	${({ localTheme }: Record<string, any>) =>
		localTheme && localTheme.message ? inputMessage : ''}

	&[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&[type='number'] {
		-moz-appearance: textfield;
	}

	&.error {
		border-color: var(---color-other-red);
	}
`;

export const InputMessageStyles = styled.div.attrs(({ className }) => ({
	className: classNames('input-message text-style-caption-medium', className),
}))<InputStyleProps>`
	${inputMessage}
`;

export const InputMessageButtonStyles = styled(Button).attrs(
	({ className }) => ({
		className: classNames('input-message', className),
	}),
)<InputStyleProps>`
	${inputMessage}
`;
