import { actionSimple } from '@legacyApp/client/modules/app/actionService';
import { BalanceElement } from '@legacyApp/types/balance/BalancesArray';
import { GameType } from '../../constants/availableGames';
import { AutobetPlayProps } from '../gameAutobet/gameAutobet.actions';
import {
	BET,
	BET_RESPONSE_ANIMATION,
	BET_RESULT,
	BET_THROTTLED,
	BET_UPDATE_ANIMATED,
	GAME_INPUT_ACTIVE,
	GAME_SET_AMOUNT_DEFAULT,
	GAME_SET_WIN_CHANCE_DEFAULT,
	GAME_UPDATE_AMOUNT,
	GAME_UPDATE_BET_ANIMATED_LAST_BALANCE,
	GAME_UPDATE_BETS_IN_ANIMATION,
	GAME_UPDATE_FORM_LOCK,
	GAME_UPDATE_PAYOUT,
	GAME_UPDATE_PROFIT,
	GAME_UPDATE_WIN_CHANCE,
	SET_GAME,
} from './game.constants';
import { BetConfig } from './@types/BetConfig';
import { PlaceBetResponse } from './@types/PlaceBetResponse';

export const bet = (payload, source = 'none') => {
	return {
		type: BET,
		payload,
		source,
	};
};

export const setGame = actionSimple(SET_GAME);

export const betThrottled = (payload) => {
	return {
		type: BET_THROTTLED,
		payload,
	};
};

export const gameInputActive = (id, bool, onModal) => ({
	type: GAME_INPUT_ACTIVE,
	id,
	bool,
	onModal,
});

export const gameSetAmountDefault = actionSimple(GAME_SET_AMOUNT_DEFAULT);
export const gameSetWinChanceDefault = actionSimple(
	GAME_SET_WIN_CHANCE_DEFAULT,
);

export const gameUpdateAmount = actionSimple(GAME_UPDATE_AMOUNT);
export const gameUpdatePayout = actionSimple(GAME_UPDATE_PAYOUT);
export const gameUpdateWinChance = actionSimple(GAME_UPDATE_WIN_CHANCE);
export const gameUpdateProfit = actionSimple(GAME_UPDATE_PROFIT);

export const gameUpdateFormLock = actionSimple(GAME_UPDATE_FORM_LOCK);

export const betResult = actionSimple(BET_RESULT);

export interface BetResponseAnimationProps {
	payload: BetConfig | AutobetPlayProps;
	data: PlaceBetResponse;
	lastBetTimestamp?: number;
}

export const betResponseAnimation = (props: BetResponseAnimationProps) => ({
	type: BET_RESPONSE_ANIMATION,
	...props,
});

export interface BetUpdateAnimatedProps {
	value: {
		id: string;
		data?: BetResponseAnimationProps;
	};
	game: GameType;
}

export const betUpdateAnimated =
	actionSimple<BetUpdateAnimatedProps>(BET_UPDATE_ANIMATED);
export const gameUpdateBetAnimatedLastBalance = actionSimple<{
	game: GameType;
	value: BalanceElement;
}>(GAME_UPDATE_BET_ANIMATED_LAST_BALANCE);

export const gameUpdateBetInAnimation = actionSimple<{
	game: GameType;
	value: {
		id: string;
		active: boolean;
	};
}>(GAME_UPDATE_BETS_IN_ANIMATION);
